import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { settings } from './const';
import { TilesProps } from './types';

import Heading from '../../molecules/Heading';
import Image from '../../atoms/Image';

const Tiles: React.FC<TilesProps> = ({ data }) => {
  const { title, items } = data;

  return (
    <Styled.Tiles>
      <Heading {...{ title }} />
      <Styled.TilesSlider>
        <Slider {...settings}>
          {items.map(({ key, label, buttonLink, image, mobileImage }) => (
            <Styled.TilesSlide {...{ key }}>
              <Styled.TilesMedia>
                <Styled.TilesImage isMobileHidden={!!mobileImage}>
                  <Image {...image} />
                </Styled.TilesImage>
                {mobileImage && (
                  <Styled.TilesImageMobile>
                    <Image {...mobileImage} />
                  </Styled.TilesImageMobile>
                )}
              </Styled.TilesMedia>

              <Styled.TilesContent>
                <Styled.TilesLabel variant="headingM">{label}</Styled.TilesLabel>

                <Styled.TilesAction {...buttonLink.link} hasOverlayMask>
                  {buttonLink.label}
                </Styled.TilesAction>
                
              </Styled.TilesContent>
            </Styled.TilesSlide>
          ))}
        </Slider>
      </Styled.TilesSlider>
    </Styled.Tiles>
  );
};

export default Tiles;
