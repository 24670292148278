import styled, { css } from 'styled-components';

import { TileImageStyledProps } from './types';

import { Inner, FlexBox } from '../../styles/Grid/styles';
import { CarouselPaginationDots } from '../../atoms/Carousel/styles';
import { positionFullStyles, transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';
import Link from '../../atoms/Link';
import { Typography } from '../../styles/Typography/styles';

export const Tiles = styled(Inner)``;

export const TilesSlider = styled.div`
  margin-top: 3rem;

  .slider {
    display: flex;
  }

  .slick-slide {
    visibility: hidden;
  }
  .slick-slide.slick-active {
    visibility: visible;
  }

  @media ${media.maxPhone} {
    position: relative;
    .slick-initialized .slick-slide {
      display: flex;
    }
    .slick-slide > div {
      width: 100%;
    }
    ${CarouselPaginationDots} {
      margin-top: 2.5rem;
    }
  }
`;

export const TilesMedia = styled.div`
  ${positionFullStyles}

  img {
    border-radius: 0.25rem; // ios fix
  }
`;

export const TilesImage = styled.div<TileImageStyledProps>`
  ${transition()}
  height: 100%;

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxPhone} {
        display: none;
      }
    `}
`;

export const TilesImageMobile = styled.div`
  ${transition()}
  height: 100%;

  @media ${media.phone} {
    display: none;
  }
`;

export const TilesContent = styled(FlexBox)`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
`;

export const TilesLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.neutral20};
`;

export const TilesAction = styled(Link)`
  @media ${media.maxPhone} {
    position: relative;
  }
`;

export const TilesSlide = styled.div`
  position: relative;
  height: 32.5rem;
  border-radius: 0.25rem;
  overflow: hidden;

  @media ${media.maxPhone} {
    display: flex !important; // override slick
  }

  @media ${media.phone} {
    width: 100%;
    margin-top: 5rem;

    &:not(:first-child) {
      margin-left: 3.125rem;
    }
    &:hover ${TilesImage} {
      opacity: 0.9;
      transform: scale(1.1);
    }
  }
`;
